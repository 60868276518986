<template>
  <div class="search-box">
    <el-form :inline="true" :model="queryForm" class="demo-form-inline">
          <el-form-item label="姓名">
            <el-input
                    v-model="queryForm.linkman"
                    placeholder="请输入姓名"
                    size="small"
                    @input="queryFeedback"
                    clearable
                    @clear="clearQueryInput"
            ></el-input>
          </el-form-item>
          <el-form-item label="产品">
            <el-input
                    v-model="queryForm.product"
                    placeholder="请输入产品"
                    size="small"
                    @input="queryFeedback"
                    clearable
                    @clear="clearQueryInput"
            ></el-input>
          </el-form-item>
          <el-form-item label="留言时间">

              <el-date-picker
                      v-model="queryForm.feedbackTime"
                      type="date"
                      size="small"
                      placeholder="选择留言时间"
                      value-format="YYYY-MM-DD"
                      @change="queryFeedback"
                      clearable
                      @clear="queryFeedback"
              >
              </el-date-picker>

          </el-form-item>

      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="submit" size="small">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="data-box">
    <el-table
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="tableData"
        style="width: 100%"
    >
           <el-table-column prop="linkman" label="姓名" align="center"/>
           <el-table-column prop="product" label="产品" align="center"/>
          <el-table-column prop="content" label="留言" align="center" show-overflow-tooltip="true"/>
          <el-table-column prop="feedbackTime" label="留言时间" align="center"/>
      <el-table-column label="操作" fixed="right" width="180" align="center">
        <template #default="scope">
            <el-button icon="el-icon-view" size="mini" @click="detail(scope.$index,scope.row)">详情</el-button>
          <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              :title="'确定删除该留言吗?'"
              @confirm="handleDelete(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button icon="el-icon-delete" size="mini" v-show="hasDeletePermision" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
<!--    留言详细弹框-->
    <el-dialog
            v-model="dialogVisible"
            :title="dialogTitle"
            width="50%"
            @close="handleClose"
    >
        <div>
            <el-descriptions>
                <el-descriptions-item label="姓名：">{{ ruleForm.linkman }}</el-descriptions-item>
                <el-descriptions-item label="产品：">{{ ruleForm.product }}</el-descriptions-item>
                <el-descriptions-item label="留言时间：">{{ ruleForm.feedbackTime }}</el-descriptions-item>
                <el-descriptions-item label="留言内容：">{{ruleForm.content}}</el-descriptions-item>
            </el-descriptions>
        </div>

    </el-dialog>

  <div class="page-box">
    <el-pagination
        v-model:currentPage="curPage"
        :page-sizes="[15, 50, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background="true"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import feedbackApi from "@/api/info/feedback";
import {ref} from "vue";

export default {
  data() {
    return {
        hasDeletePermision:false,
      queryForm: {
            linkman: "",
            product: "",
            feedbackTime: "",
            content: "",
        pageSize: 15,
        curPage: 1,
      },
      loading: false,
      total: 0,
      tableData: [],
      isSave: false,
      ruleForm: {
              id: "",
              linkman: "",
              product: "",
              feedbackTime: "",
              content: "",
      },
      rules: {

      },
      dialogTitle: "",
      dialogVisible: false,
    };
  },
  mounted() {
    this.queryFeedback();
      this.$nextTick(()=> {
          // 获取设置按钮权限
          this.hasDeletePermision = this.$menuUtil.hasMenu('/info/feedbackManage:delete');
      })
  },
methods: {
  clearQueryInput(){
    this.queryFeedback();
  },
  submit() {
    this.queryForm.curPage = 1;
    this.queryFeedback();
  },
  handleSizeChange(val) {
    this.queryForm.pageSize = val;
    this.queryFeedback();
  },
  handleCurrentChange(val) {
    this.queryForm.curPage = val;
    this.queryFeedback();
  },
  queryFeedback() {
     feedbackApi.queryFeedback(this.queryForm)
        .then((response) => {
          this.tableData = response.data.data.records;
          this.total = response.data.data.total;
        });
  },
  handleDelete(index, row) {
     feedbackApi.delFeedback(row.id).then((response) => {
      if (response.data.code == 1) {
        this.$notify({
          title: response.data.msg,
          type: "success",
          offset: 40,
          duration: 2000,
        });
        this.tableData.splice(index, 1);
      } else {
        this.$notify.info({
          title: response.data.msg,
          type: "warning",
          offset: 40,
          duration: 3000,
        });
      }
    });
  },
    detail(index, row) {
        console.log(index, row);
        this.dialogTitle = "留言详情";
        this.dialogVisible = true;
        this.ruleForm = row;
    },
  // 弹窗关闭事件
  handleClose() {
    this.ruleForm = {};
  },
},
};
</script>

<style scoped>
  .is-penultimate > .el-tree-node__content {
    color: red;
  }
</style>